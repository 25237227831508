@import '../../assets/scss/main';

.swiper {
  &__container {
    flex-grow: 1;
    padding: 1em 0;
    overflow-y: hidden;

    & > div {
      height: 100%;
    }
  }
}
