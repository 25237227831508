/* theme.css to autocompletetags*/



.react-tagsinput { 
    border: 0px;
    background-color: inherit;
    font-size: x-small;
    padding-left: 0px !important;
    padding-top: 0px !important;

}

.react-tagsinput-tag {
    border-width: 2px;
    border-color: #ffc213;
    background-color: inherit;
    color: #8b49f6;
    border: 0px;
    padding-left: 5px;
}
div.show-desktop .react-tagsinput-tag {
    font-size: x-small;
    font-weight: normal;
    padding: 0 0 0 5px;
    margin: 0px;

  }
  

.react-tagsinput-input {
    font-size: 1.5em;
    font-weight: normal;

}
  

.react-autosuggest__suggestions-list {
    background-color: white;
}
