@import '../../../assets/scss/main';
@import '../../../assets/scss/roundeddiv';

@mixin headerbutton($size) {
  @include rounded-div(100%);
  height: $size;
  width: $size;
  margin: 0 1em;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  & > .headericon {
    height: 50%;
  }
}