@import '../../assets/scss/main';
@import '../../assets/scss/roundeddiv';


.topsearch {
  flex-grow: 1;
  flex-shrink: 1;
  // margin: 0 3em;
  position: relative;

  // @media screen and (max-width: 1024px) {
  //   // position: absolute;
  //   // width: 100%;
  //   // margin: 0;
  //   // padding: 0 1em;
  //   // bottom: -60px;
  // }


  // @media screen and (max-width: 800px) {
  //   // position: absolute;
  //   // width: 100%;
  //   // margin: 0;
  //   // padding: 0 1em;
  //   // top: 60px;
  // }



  &__items {
    display: flex;
    position: relative;
    justify-content: space-between;
    z-index: 3;
    // max-height: 6vh;
    @include rounded-div(25px);

    // @media screen and (max-width: 1024px) {
    //   top: 2vh;
    // }

    
  }

  &__mobilebar {
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
  }


  &__icon {
    position: relative;
    height: 20px;
  }

  &__modalbackdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: black;
    opacity: .4;
    // backdrop-filter: blur(5px);
  }

  &__select {
    text-transform: uppercase;
    position: relative;
    min-width: 120px;
    padding: .9em .9em .9em 1.5em;

    button, input, input::placeholder, button:focus {
      text-transform: uppercase;
      border: none !important;
      outline: none !important;
      background-color: unset;
      color: inherit;
      text-align: left;
      width: 100%;
      padding: unset;
      cursor: pointer;
    }

    &::before {
      content: '';
      width: 2px;
      height: calc(100% - 1em);
      left: 0;
      top: .5em;
      position: absolute;
      margin: 0 1em;
    }
    @include colorbars(before);

    &::after {
      content: '';
      width: 25px;
      height: calc(100% - 1em);
      right: 0;
      top: .5em;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z" fill="%23282828"></path></svg>');
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      // margin: 0 1em;
    }

    &:nth-child(1) {
      &::before {
        content: unset; // Remove color bar from first item
      }
    }

    &:nth-child(4) {
      border-radius: 0 25px 0 0;
      &::after {
        padding-right: 4em;
      }
    }

    &.selected {
      background-color: #f9f7f6;
      z-index: 5;
      // box-shadow: 0px 35px 0px 0px #f9f7f6;

      &::after{
        transform: rotate(90deg);
      }
    }



  }

  &__main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    z-index: 4;
    cursor: pointer;
    height: 100%;

    // @media screen and (max-width: 1024px) {
    //   height: 11vh;
    // }


    &.topsearch__button {
      max-width: 2em;
      padding: 0.9em 0em .9em 0;
      position: absolute;
      right: 0;
    }

    &.topsearch__mobilebutton {
      // @include rounded-div(100%);
      height: 50px;
      flex-grow: 0;
      display: cursor;
      display: flex;
      align-items: center;
      width: 100%;

    }
  }

}

.mobile_icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
}


.mobile_button {
  border-radius: 7px;
  border-style: none;
  text-align: center;
  width: 90px;
  font-weight: border;
  border-style: solid;
  border-right: 0;
  border-left: 0;
  background-color: #ffffff;
}




.mobile_button {

  

  &__tag-button {
    border-color: #8b49f6;

  }

  &__location-button {
    border-color: #ffc213;

  }

  &__date-button {
    border-color: #fe1d5b;
  }

  &__category-button {
    border-color: #39b44a;
  }

}

.mobile-selected {
  opacity: 0.4;
}

.mobile_tags {
  padding-left: 10px;
}

.react-tagsinput-input {
  width: 100% !important;
}

