@import '../../assets/scss/main';

.footer {
  display: flex;
  height: 35px;
  color: white;
  margin-top: auto;

  &__color {
    width: 25%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7em;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
    }
    @include colorbars(before);
  }

  &__addposter {
    & > div {
      // position: absolute;
      bottom: 30%;
      z-index: 10;
      position: relative;
    }
  }

  &__mobile {
    img {
      height: 65%;
    }
  }

  &__links {
    display: flex;
    justify-content: space-evenly;
    text-transform: uppercase;


    span {
      // padding: 0 1vh;
      text-align: center;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
    }

    img {
      height: 2vh;
    }
  }

  &__logo {
    img {
      max-height: 50%;
      max-width: 90%;
    }
  }
}
