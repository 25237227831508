.poster-template-blocks .poster-template {
  width: 190px;
}

.poster-template-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.template {
  max-width: 180px;
  max-height: 300px;
}