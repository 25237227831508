
 .gallery {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}


.title {
  font-family: 'Carter One';
}
.container {
  margin-top: 50px;
  text-align: center;
}

.image-item {
  // max-width: 300px;
  // max-height: 400px;
  margin-top: 1vh;
}

.image-grid {
  column-count: 1;


  img{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media only screen and (min-width: 600px) {
  .image-grid  {
    column-count: 1;
  }
}

@media only screen and (min-width: 800px) {
  .image-grid  {
    column-count: 2;
  }
}

@media only screen and (min-width: 1000px) {
  .image-grid  {
    column-count: 3;
  }
}

@media only screen and (min-width: 1200px) {
  .image-grid  {
    column-count: 4;
  }
}

@media only screen and (min-width: 1500px) {
  .image-grid  {
    column-count: 5;
  }
}

@media only screen and (min-width: 1800px) {
  .image-grid  {
    column-count: 6;
  }
}

@media only screen and (min-width: 3000px) {
  .image-grid  {
    column-count: 7;
  }
}