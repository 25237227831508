.poster-statistics-preview {
  max-width: 200px;
   
}

.statistics-container {
  display: flex;
  margin-bottom: 3vh;
  margin-top: 3vh;
  flex-flow: wrap;
}

.preview-poster {
  margin: 2vh;
  /* position: fixed;
  left: 10px;
  top: 160px; */
}


.chart-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 650px; */
  align-items: center;
  margin: 2vh;
}




@media screen and ( max-width: 1000px ){
  /* .chart-container {
      margin-left: 210px;
  } */
}

@media screen and ( min-width: 1001px ){
  .statistics-container {
    justify-content: center;
  }
}