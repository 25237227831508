.Mosaic {
    width: 100%;
    height: 100vh;
    background-color: #000;
  }
  

/* .react-swipe-container div {
   width: 3000px !important;
} */

/* .swipe-posters {
  width: 100vw !important;
} */