.tui-image-editor-header-logo {
  display: none;
}

button .tui-image-editor-download-btn {
  display: none;
}
.poster-editor {
  display: flex;

}

.template-editor {
  margin: 5px;
  /* position: fixed; */
}


.all-templates {
  margin-left: 400px;
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.header-buttons {
  padding-left: 1vh;
}