

.add-poster-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 5px;
}


.add-poster-column {
  display: flex;
  flex-direction: column;
}

.add-poster-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 330px;
}

.row-left {
  flex: 0 35%;
  padding-bottom: 20px;
  align-self: center;
}

.row-right {
  flex: 0 60%;
  padding-bottom: 20px;
}

.uploaded-file {
  max-width: 100px;
  max-height: 140px;
}

.h2-title {
  color: #3fbac2;
  font-weight: 600;
  text-align: center;
  padding: 5px;
}

.upload-button {
  align-self: center;
  max-width: 200px;
}

.upload-section {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}