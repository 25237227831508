@import './assets/scss/main.scss';

.app {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    // overflow: hidden;
  }
}
