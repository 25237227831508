@import '../../assets/scss/main';

.swiper {
  &-container {
    height: 100%;
  }
}

.swiper__container_infinitive {
  padding: 0px !important;
  // overflow-y: none !important;
}



.gallery {
  height: 100%;

}

.axis-vertical {
  height: auto !important;
}