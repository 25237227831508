

.posters-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.posters-container {
  max-width: 1400px !important;
}

.my-posters-header {
  display: flex;
  justify-content: space-evenly;
  margin: 5px;
}

.posters-container.header.title {

}