.subheader {

  display: flex;
  justify-content: space-between;
  width: 100%;

  color: #fe1d5b;

}

.modal-title a {
  color: #fe1d5b;
}



.left-icons {
  display: flex;
  justify-content: end;
  width: 100%;
}

.react-share__ShareButton {
  height: 50px;
}