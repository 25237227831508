/* .form-inline .search-form-control {
 display:inline-block;
 width:auto;
 max-width: 160px;
 min-width: 80px;
 vertical-align:middle
} */


.search-button {
  margin: 3px;
}
 



.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  }
  
  .flex-col-left {
    flex: 30%;
    align-self: center;
    text-align: left;
    padding-left: 8px;
}
  
  .flex-col-right {
    padding-top:5px;

    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex: 70%;
    /* flex-direction: column; */
    color: black;
    box-sizing:border-box;
    /* flex-basis: auto; */
  }

.flex-col-right input {
  max-width: 195px;
}

