@import 'main';
@import 'roundeddiv';

@mixin search-modal($direction) {
  position: absolute;
  width: 100%;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: $direction;
  justify-content: space-evenly;
  padding: 5vh 1em 30px 1em;
  left: 0;
  @include rounded-div(25px);
  top: 0;
  // background-color: rgba(249, 249, 249, 1);
  background-color: rgba(249, 247, 246, 1);
}
