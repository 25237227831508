.gallery {

  display: grid;
  grid-auto-flow: dense;
  grid-gap: 3em;
  grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
  grid-auto-rows: 30%;

  // display: grid;
  // grid-auto-flow: dense;
  // grid-gap: 1em; /* [1] Add some gap between rows and columns */
  // grid-template-columns: repeat( auto-fill, minmax( 10vw, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
  // // grid-auto-rows: 10vh; /* [3] Set the height for implicitly-created row track */
  // grid-auto-rows: 10%; /* [3] Set the height for implicitly-created row track */
  // // min-height: 80vh;

  @media screen and (orientation:portrait) {
    grid-template-columns: repeat( auto-fill, minmax( 15vw, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
    grid-auto-rows: 7%; /* [3] Set the height for implicitly-created row track */
  }
  padding: 1vh;
}

.gallery__item {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, .3))
            drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
  &.horizontal {
    @for $i from 1 through 2 {
      &.horizontal-#{$i} {
        grid-column-end: span #{2 + $i};
        // grid-row-end: span #{$i};
        grid-row-end: span #{$i};
      }
    }
  }
  &.vertical {
    align-items: center !important;
    @for $i from 1 through 2 {
      &.vertical-#{$i} {
        grid-row-end: span #{2 + $i};
        grid-column-end: span #{$i};
      }
    }
  }
}

.gallery__img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  transition: transform .2s;
  min-height: 10vh;
  cursor: pointer;
  outline: 4px solid white;

  &:hover {
    transform: scale(1.2);
    z-index: 9999;
  }

}
