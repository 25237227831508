@import '../../../../assets/scss/main';
@import '../../../../assets/scss/searchmodal';

.locationsmodal {
  @include search-modal(column);

  padding: 8vh 1em 30px 1em;
  

  &__location {
    text-align: left;
    padding: .5em;
    position: relative;
    cursor: pointer;
    max-width: 50%;

    @include colorbars(after);
    &:after {
      content: "";
      height: 100%;
      left: 0;
      top: 0;
      width: 3px;
      position: absolute;
      transition: all 0.3s ease 0s;
      -webkit-transition: all 0.3s ease 0s;
      z-index: -1;
    }

    &:hover:after{ width: 100%; }

  }
}

.locationsmodal.mobile{
  top: 110px;
  padding: 5vh 1em 30px 1em;
}
