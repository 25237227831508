.container.select-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


label.label-upload {
    display: inline;
}

.uploaded-file {
    margin: 4px;
}

.uploaded-image {
    justify-content: center;
    display: flex;
}

.middle-div {
    margin-left: 5px;
    margin-right: 5px;
}
