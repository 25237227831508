@import '../../../../assets/scss/main';
@import '../../../../assets/scss/searchmodal';

.calendarmodal {
  @include search-modal(row);

  @media screen and (min-width: 1024px) {
    padding: 7vh 1em 30px 1em;
  }

  @media screen and (max-width: 1024px) {
    padding: 12vh 1em 30px 1em;
  }

}

.calendarmodal.mobile {
  top: 80px;
  padding: 5px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  background-color: white;
  box-shadow: none;
}