.login-form {
    min-width:300px;
    border-radius: 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 0px solid #000000;
}


.message--alert {
    color: #f04646;
    font-weight: 600;
  }
  