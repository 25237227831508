
.react-autosuggest__container {
  position: relative;
  /* max-width: 160px;  */

}
.react-autosuggest__input {
  width: inherit;
  height: inherit;
  padding: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: inherit;
  border-radius: inherit;
}

/* .react-autosuggest__input {
  width: 240px;
  height: 20px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 4px;
} */

.react-autosuggest__input--focused {
  outline: none;
}

.header-location .react-autosuggest__suggestions-container {
  display: none !important;
}

.show-suggestions.mobile {
  border-radius: 25px;
  width: 100%;
  // border-color: white !important;
  border-style: none !important;
}

.show-suggestions.mobile:focus-visible {
  // border-color: white !important;
  border-style: none !important;


}