@import '../../../../assets/scss/main';

div.rdrDateDisplay {
  display: none;
}

.rdrCalendarWrapper {
  padding: 1em;
  border-radius: 25px;

  .rdrMonthAndYearPickers {
    select {
      text-transform: uppercase;

      &::-ms-expand {
        display: none;
      }
      appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }
  }

  .rdrWeekDay {
    position: relative;
    height: 40px;
    color: black;
    margin-bottom: 10px;
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      position: absolute;
    }
    @include colorbars(after);
  }
}
