.thumbnail {
    max-height: 140px;
    max-width: 100px;
  }


/* .poster-row .btn {
    margin-left: 5px;
} */

.poster-row {
    display: flex;
    width: 300px;
    min-height: 160px;
    background-color: #d3d4d8;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 8px;
    padding-left: 10px;
  }

  /* .poster-row:hover {
    opacity: 0.4;
  }

  .poster-row:active {
    opacity: 0.4;
  } */


.poster-column {
    /* flex: 1 0 21%;  */
    align-self: center;
}

.poster-checkbox {
  align-self: center;
  margin-right: 5px;
}

.poster-buttons {
  display: row;
}

.poster-column-row {
  padding-left: 5px;
}

.poster-column-row.buttons {
  justify-content: flex-end;
  display: flex;
}

.poster-column-row.poster-activate {
  justify-content: flex-end;
  display: flex;
}

.poster-column-row.poster-info {
  text-align: left;
}

.btn.poster-button {
  padding: 0rem .25rem;
  margin-right: 5px;
}

.manage-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 100%;
  width: 100%;
}

.poster-info p {
  margin-bottom: 0px;
  font-size: 11px;
}

.btn:hover {
  opacity: 0.5;
}

.btn.btn-active {
  background-color: #39b54a;
}

.btn.btn-inactive {
  background-color: #fe1d5b;
}

.btn.btn-statistics {
  background-color: #ffffff;
}

.btn.btn-edit {
  background-color: #ffffff;
}
.btn.btn-delete {
  background-color: #fe1d5b;
}
