@import '../../../../assets/scss/main';
@import '../../../../assets/scss/searchmodal';

@mixin colorcircle {
  background-size: 100% 100%; /* Fix for Fifefox image scaling */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><style>.cls-1{fill:%23fe1d5b;}.cls-2{fill:%23ffc213;}.cls-3{fill:%2339b54a;}.cls-4{fill:%238b49f6;}</style></defs><g id="Warstwa_2" data-name="Warstwa 2"><g id="Warstwa_1-2" data-name="Warstwa 1"><path class="cls-1" d="M34.14,5.86,32.73,7.27a18,18,0,0,1,0,25.46l1.41,1.41A20,20,0,0,0,34.14,5.86Z"/><path class="cls-2" d="M20,2A17.94,17.94,0,0,1,32.73,7.27l1.41-1.41a20,20,0,0,0-28.28,0L7.27,7.27A17.94,17.94,0,0,1,20,2Z"/><path class="cls-3" d="M20,38A17.94,17.94,0,0,1,7.27,32.73L5.86,34.14a20,20,0,0,0,28.28,0l-1.41-1.41A17.94,17.94,0,0,1,20,38Z"/><path class="cls-4" d="M2,20A17.94,17.94,0,0,1,7.27,7.27L5.86,5.86a20,20,0,0,0,0,28.28l1.41-1.41A17.94,17.94,0,0,1,2,20Z"/></g></g></svg>');
  animation-direction: initial;
  animation:spin 4s linear infinite;
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}

.tagsmodal {
  @include search-modal(row);

  display: flex;
  flex-direction: column;
  padding-top: 100px;



  &__characters {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    cursor: pointer;
    .character {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.1em;
      height: 2.1em;
      text-transform: uppercase;
      padding: 1em;
      border-radius: 100%;
      background-color: white;
      animation: inherit;
      animation-direction: reverse;

      &__wrapper {
        display: flex;
        width: 2.6em;
        height: 2.6em;
        align-items: center;
        flex-shrink: 0;
        justify-content: center;
        &:hover, &--selected {
          @include colorcircle();
        }
      }
    }
  }

  &__tags {
    text-transform: capitalize;
    cursor: pointer;

    display: flex;
    flex-basis: auto;
    flex-direction: row;
    flex-wrap: wrap;


    .tag {
      border: 1px solid grey;
      border-radius: 12px;
      padding: .3em;
      margin: .4em;
    }
  }

}

.tagsmodal.mobile {
  margin-top: 130px;
  padding-top: 15px;
}