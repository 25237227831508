@import '../../../../assets/scss/main';
@import '../../../../assets/scss/searchmodal';

.categorymodal {
  @include search-modal(row);

    padding: 4vh 1em 30px 1em;
 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__tile {
    max-width: 16%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1em 1em;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 60%;
    position: relative;
    flex-shrink: 0;

    @media (min-width: 1468px) and (orientation: landscape) {
      max-width: 8%;
    }


    @media screen and (max-width: 1024px) {
      max-width: 10vh;
    }

    .categoryimage {
      width: 100%;
      height: auto;

      &__container {
        min-height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
          min-height: 10vh;
        }
      }

      &__label {
        text-align: center;
        letter-spacing: .1em;
        font-size: 1.1em;
        line-break: anywhere;
        @media screen and (max-width: 1024px) {
          font-size: .9em;
        }
      }

      &__dots {
        width: 1.2em;
        display: block;
        margin: 1em auto;
      }
    }
  }
}

.subcategory {
  &__item {
    margin: .5em 0;
    width: 100%;
    min-width: 600px;
    text-align: left;
    text-transform: capitalize;
    &:hover {
      color: $accent-purple;
    }
  }

}


.categorymodal.mobile {
  top: 100px;
}