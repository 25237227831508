@import 'common';

.mobilemenu {
  height: 35px;
  width: 35px;
  margin: 0 1em;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &__flagicon {
    width: 22px;
    margin-right: 1vh;
  }

  & > .headericon {
    height: 50%;
  }

  &__container {
    position: absolute;
    right: 0;
    top: 1vh;
    height: 100%;
    // display: flex;
    align-items: center;
  }

  &__menu {
    @include rounded-div(25px);
    min-width: 140px;
    z-index: 5;
    position: absolute;
    right: 1em;
    top: 5vh;
    background-color: #ffffff;


  }

  &__list {
    list-style-type: none;
  }
}
