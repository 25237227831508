
.react-autosuggest__container {
  position: relative;
  /* max-width: 160px;  */
  
}
.react-autosuggest__input {
  width: inherit;
  height: inherit;
  padding: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: inherit;
  border-radius: inherit;
}

/* .react-autosuggest__input {
  width: 240px;
  height: 20px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 4px;
} */

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header-location .react-autosuggest__suggestions-container {
  display: none;
}

.show-suggestions .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0px 5px;
  font-size: 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
