@import '../../assets/scss/main';

.login {
  position: relative;
  &__activemenu {
    display: flex;
    flex-flow: column;
    min-width: 150px;
    text-align: center;
  }

  &__inactivemenu {

  }
}

.welcome-user {
  color: $accent-red;
}

.form-inline {
  input {
    margin: 0.3vh;
  }

  button {
    margin: 0.3vh;
  }

  div {
    margin: 0.3vh;
  }
}


