@import 'colors';

@font-face {
    font-family: 'BigJohnPRO-Regular';
    src: url('../fonts/BigJohnPRO-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'BigJohnPRO-Light';
    src: url('../fonts/BigJohnPRO-Light.otf') format('opentype');
}
@font-face {
    font-family: 'BigJohnPRO-Bold';
    src: url('../fonts/BigJohnPRO-Bold.otf') format('opentype');
}

@mixin colorbars($placement) {
  &:nth-child(4n + 1) {
    &::#{$placement} {
      background-color: $accent-purple;
    }
  }
  &:nth-child(4n + 2) {
    &::#{$placement} {
      background-color: $accent-yellow;
    }
  }
  &:nth-child(4n + 3) {
    &::#{$placement} {
      background-color: $accent-red;
    }
  }
  &:nth-child(4n + 4) {
    &::#{$placement} {
      background-color: $accent-green;
    }
  }
}

.show-desktop {
  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

// .show-tablet {
//   display: none;
//   @media screen and (max-width: 1024px) and (min-width: 600px) {
//     display: block !important;
//   }
// }
.show-mobile {
  @media screen and (min-width: 801px) {
    display: none !important;
  }
}

body {
  font-family: 'BigJohnPRO-Regular', sans-serif !important;
  background-color: $main-background;
  margin: 0;
  color: $font-grey;
  display: block;
  // overflow: hidden;
}

#root {
  // overflow: hidden;
  overflow: visible;
}

.custom-modal {
  position: absolute;

}

.display-block {
  display: block;
}


.btn-viposter {

  background: linear-gradient(#ffffff, #ffffff),/* black turned into gradient to hold with background-clip and hide the 2 color gradient under it */
    linear-gradient(/* 2 colors to draw under the borders also via background-clip*/
        10deg,
        $accent-purple 0%,
        $accent-yellow 25%,
        $accent-red 50%,
        $accent-green 75%,
        $accent-purple 100%
      )
      no-repeat center center;

      background-clip: 
      padding-box, /* drawn inside including padding area */
      border-box;/* drawn also under borders */
    background-size:
    100% 100%, 
    110% 150%;
    // color: white;
    // box-shadow: red;/* did you want this too ? */
  
    // vertical-align: top;
    // border: 7px solid transparent;/* give extra space for gradients colors */
    // font-size: 2.5rem;
    // margin-bottom: 0.25em;
    // padding: 0.5em 2em;

}