@import 'common';

.usermenu {
  @include headerbutton(50px);
  &__container {
    position: relative;
  }

  &__menu {
    @include rounded-div(25px);
    padding: 10px;
    z-index: 7;
    position: absolute;
    right: 1em;
    top: 60px;
    background-color: $nav-background;
  }
}
