@import '../../assets/scss/main';

.appheader {
  background-color: $header-background;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  padding: 1vh;
  
  justify-content: space-around;
  // @media screen and (max-width: 1024px) {
  //   max-height: 10vh;
  // }


  &__icons {
    display: flex;
  }


  .topcolors {
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;

    div {
      width: 25%;
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
      }
      @include colorbars(after);
    }
  }

  .logo {
    &__container {
      width: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 1em;

      @media screen and (max-width: 800px) {
        width: 100%;
        max-height: 150px;
      }
    }



    &__image {
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        @media screen and (max-width: 1024px) {
          max-height: 80px;
          height: 5vh;
        }
      }
    }
  }
}



.logo__container{
  // width: 20%;
  order: 1;
}

.topsearch {
  // width: 60%;
  order: 2;
}

.appheader__icons{
  order: 3;
}

@media all and (max-width: 1024px) {
  .appheader{
    flex-wrap:wrap;
  }
  .topsearch {
    margin-top: 2vh;
    width: 100%;
    order: 3;
  }
  
  .logo__container{
    width: 50%;
  }
  .appheader__icons{
    order:2;
    
  }
}